import { createContext, useContext } from 'react';

export interface IProductContext {
  product: string;
  productChooser: boolean;
  setProduct: (product: string) => void;
  setProductChooser: (productChooser: boolean) => void;
}

export const ProductContext = createContext<IProductContext>({
  product: '',
  productChooser: false,
  setProduct: () => ({}),
  setProductChooser: () => ({}),
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGlobalContext = () => useContext(ProductContext);
