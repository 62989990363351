import { IRoute } from '../typings/route.type';
import { LeaderboardPage } from '../../features/leaderboard/pages/LeaderboardPage';
import { LeaderboardWidgetPage } from '../../features/leaderboard/pages/LeaderboardWidgetPage';
import { ContributionsPage } from '../../features/contributions/pages/ContributionsPage';
import { ProfilePage } from '../../features/profile/pages/ProfilePage';
import { AboutPage } from '../../features/about/pages/AboutPage';
import { URL_LIDERBOARD, URL_LIDERBOARD_FRAME, URL_PROFILE, URL_CONTRIBUTIONS, URL_ABOUT } from './url';

export const GUEST_ROUTES: IRoute[] = [
  {
    path: URL_LIDERBOARD,
    exact: true,
    component: LeaderboardPage,
    layout: 'GuestLayout',
  },
  {
    path: URL_PROFILE,
    exact: true,
    component: ProfilePage,
    layout: 'GuestLayout',
  },
  {
    path: URL_CONTRIBUTIONS,
    exact: true,
    component: ContributionsPage,
    layout: 'GuestLayout',
  },
  {
    path: URL_ABOUT,
    exact: true,
    component: AboutPage,
    layout: 'GuestLayout',
  },
  {
    path: URL_LIDERBOARD_FRAME,
    exact: true,
    component: LeaderboardWidgetPage,
    productDisabled: true,
    layout: 'WidgetLayout',
  },
];
export const GUEST_DEFAULT_ROUTE = URL_LIDERBOARD;

export const AUTH_ROUTES: IRoute[] = [];
export const AUTH_DEFAULT_ROUTE = URL_LIDERBOARD;
