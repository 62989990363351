import React from 'react';
import classnames from 'classnames';

import s from './View.module.scss';

import { SubHeader } from '../SubHeader/SubHeader';
import { TitleTableView } from '../TitleTableView/TitleTableView';
import { LoadMore } from '../LoadMore/LoadMore';
import { Spinner } from '../Spinner/Spinner';
// import { WidgetHead } from '../WidgetHead/WidgetHead';

interface IView {
  type?: 'head' | 'noHead' | 'widget';
  children?: React.ReactNode;
  componentSubHeader?: React.ReactNode;
  title?: string;
  loadingLoadMore?: boolean;
  loading?: boolean;
  loadingLoadShow?: boolean;
  description?: string;
  nextPage?: () => void;
}

export const View: React.FC<IView> = ({
  children,
  loading = false,
  loadingLoadMore = false,
  loadingLoadShow = false,
  nextPage,
  componentSubHeader,
  type = 'noHead',
  title,
  description = '',
}) => (
  <React.Fragment>
    {type === 'head' && (
      <div className={s.viewHead}>
        <SubHeader>{componentSubHeader}</SubHeader>
        <TitleTableView title={title || ''} description={description} />
      </div>
    )}
    {/* {type === 'widget' && <WidgetHead title={title} description={description} />} */}
    {!loading && <div className={classnames({ [s.viewContent]: type !== 'widget' })}>{children}</div>}
    {loading && <Spinner />}
    {!loading && loadingLoadShow && (
      <div className={s.viewLoadMore}>
        <LoadMore loading={loadingLoadMore} nextPage={nextPage} />
      </div>
    )}
  </React.Fragment>
);
