import React from 'react';

import { Input as AntInput } from 'antd';

import s from './Input.module.scss';

interface IInput {
  inputRef?: React.RefObject<AntInput>;
  allowClear?: boolean;
  value?: string | number;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Input: React.FC<IInput> = ({ placeholder, allowClear, inputRef, value, onChange }) => (
  <AntInput
    allowClear={allowClear}
    placeholder={placeholder}
    value={value}
    ref={inputRef}
    onChange={onChange}
    className={s.input}
  />
);
