import React from 'react';

import { Spinner } from '../Spinner/Spinner';
import { TableHead } from '../TableHead/TableHead';
import { TableBody } from '../TableBody/TableBody';
import { TableRow } from '../TableRow/TableRow';
import { TableCell } from '../TableCell/TableCell';
import { LoadMore } from '../LoadMore/LoadMore';

import { IColumn } from './typings/table-column.type';

import s from './Table.module.scss';

interface ITable {
  children?: React.ReactNode;
  columns: IColumn[];
  loading?: boolean;
  loadingLoadMore?: boolean;
  showLoadMore?: boolean;
  nextPage?: () => void;
}

export const Table: React.FC<ITable> = ({
  nextPage,
  showLoadMore = false,
  loadingLoadMore = false,
  loading = false,
  children,
  columns,
}) => (
  <React.Fragment>
    {!loading && (
      <React.Fragment>
        <table className={s.table}>
          <TableHead>
            <TableRow>
              {columns.map((current) => (
                <TableCell
                  key={current.title}
                  type="th"
                  tabletDisabled={current.tabletDisabled}
                  mobileDisabled={current.mobileDisabled}
                >
                  {current.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </table>
        {showLoadMore && (
          <div className={s.viewLoadMore}>
            <LoadMore loading={loadingLoadMore} nextPage={nextPage} />
          </div>
        )}
      </React.Fragment>
    )}
    {loading && <Spinner />}
  </React.Fragment>
);
