import React from 'react';

import { Logo } from '../Logo/Logo';
import { Menu } from '../Menu/Menu';

import s from './HeaderGuest.module.scss';

export const HeaderGuest: React.FC = () => (
  <header className={s.wrapperHeader}>
    <div className={s.headerContent}>
      <Logo />
      <Menu />
    </div>
  </header>
);
