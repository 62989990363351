import axios from 'axios';

import { IApi } from '../typings/api.type';

import { createUrl } from './create-url';

export const http = axios.create({
  baseURL: '/api/v1',
  responseType: 'json',
  timeout: 6000,
  headers: {},
});

export const authHttp = (token: string): void => {
  http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

http.interceptors.response.use(
  (response) => response,
  (error) => error,
);

export const httpGET = async <T>({ url, params, options }: IApi): Promise<T | null> => {
  try {
    const res = await http.get<T>(createUrl(url, options), { params: params });
    if (res.status === 200) {
      return res.data;
    } else {
      console.log(123);
      // FIXME: ERROR HTTP
      return null;
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const httpPOST = async <T>({ url, options, body }: IApi): Promise<T | null> => {
  try {
    const res = await http.post<T>(createUrl(url, options), body);
    if (res.status === 200 || res.status === 201 || res.status === 202) {
      return res.data;
    } else {
      console.error('STATUS: ', res.status);
      // FIXME: ERROR HTTP
      return null;
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const httpPUT = async <T>({ url, options, body }: IApi): Promise<T | null> => {
  try {
    const res = await http.put<T>(createUrl(url, options), body);
    if (res.status === 200) {
      return res.data;
    } else {
      console.error('STATUS: ', res.status);
      // FIXME: ERROR HTTP
      return null;
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const httpDELETE = async <T>({ url, options }: IApi): Promise<T | null> => {
  try {
    const res = await http.delete<T>(createUrl(url, options));
    if (res.status === 200) {
      return res.data;
    } else {
      console.error('STATUS: ', res.status);
      // FIXME: ERROR HTTP
      return null;
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};
