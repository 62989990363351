import React from 'react';

import { Avatar } from '../../../../common/components/Avatar/Avatar';

import { ProfileContact } from '../ProfileContact/ProfileContact';

import s from './ProfileHeader.module.scss';

import { IDevs, ISummary } from '../../../leaderboard/typings/devs.type';
import { ReactComponent as AwarenessIcon } from '../../../../assets/images/icons/rupor.svg';
import { ReactComponent as CommunityIcon } from '../../../../assets/images/icons/community_green.svg';

export const ProfileHeader: React.FC<{ user: IDevs | null; contentSummary?: ISummary; answersSummary?: ISummary }> = ({
  user,
  contentSummary,
  answersSummary,
}) => (
  <div className={s.profileHeader}>
    <div className={s.profile}>
      <Avatar src={user?.avatarUrl || ''} leftRound large mobileSmall />
      <div className={s.profileContent}>
        <div className={s.profileTopContent}>
          <div className={s.profileTopContentLeft}>
            <h3>{user?.name}</h3>

            <div className={s.profileRank}>
              {contentSummary && contentSummary.score > 0 && (
                <div className={s.profileRankContent}>
                  <AwarenessIcon />
                  <div>
                    PROJECT AWARENESS: <strong>{contentSummary.score}</strong> <br />
                    RANK: {contentSummary?.rank} OF {contentSummary?.usersCount}
                  </div>
                </div>
              )}
              {answersSummary && answersSummary.score > 0 && (
                <div className={s.profileRankAnswers}>
                  <CommunityIcon />
                  <div>
                    COMMUNITY SUPPORT: <strong>{answersSummary.score}</strong> <br />
                    RANK: {answersSummary?.rank} OF {answersSummary?.usersCount}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={s.profileTopContentRight}></div>
        </div>
        <div className={s.profileBottomContent}>
          <ProfileContact github={user?.github} email={user?.email} stackoverflow={user?.stackoverflow} />
          <div className={s.profileBottomContentRight}></div>
        </div>
      </div>
    </div>
    <div className={s.mobileProfile}>
      <ProfileContact github={user?.github} email={user?.email} />
    </div>
  </div>
);
