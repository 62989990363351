import React from 'react';

import { Cooperate } from '../Cooperate/Cooperate';

import s from './FooterGuest.module.scss';

export const FooterGuest: React.FC = () => (
  <footer className={s.footerWrapper}>
    <div className={s.footerContent}>
      <Cooperate />
    </div>
  </footer>
);
