import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { GUEST_ROUTES, GUEST_DEFAULT_ROUTE } from '../../common/constants/routes';
import { GuestLayout } from '../../common/layouts/GuestLayout';
import { WidgetLayout } from '../../common/layouts/WidgetLayout';

import { changeProduct } from '../../common/utils/product';

export const GuestRoutes: React.FC = () => (
  <Switch>
    {GUEST_ROUTES.map((routeItem, index) => (
      <Route
        key={index}
        path={routeItem.path}
        exact={routeItem.exact}
        render={() => {
          let Layout = GuestLayout;
          if (routeItem.layout === 'WidgetLayout') {
            Layout = WidgetLayout;
          }

          if (!routeItem.productDisabled) {
            changeProduct();
          }

          return (
            <React.Fragment>
              <Layout>
                <routeItem.component />
              </Layout>
            </React.Fragment>
          );
        }}
      />
    ))}
    <Redirect to={GUEST_DEFAULT_ROUTE} />
  </Switch>
);
