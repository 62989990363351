import React from 'react';
import classnames from 'classnames';

import { Icon } from '../Icon/Icon';

import s from './Avatar.module.scss';

export const Avatar: React.FC<{
  mobileSmall?: boolean;
  round?: boolean;
  small?: boolean;
  large?: boolean;
  leftRound?: boolean;
  src: string;
}> = ({ mobileSmall, small, large, src, round = false, leftRound = false }) => (
  <React.Fragment>
    <div
      className={classnames(s.avatarWrapper, {
        [s.avatarLeftRound]: leftRound,
        [s.small]: small,
        [s.large]: large,
        [s.largeWrapper]: large,
        [s.round]: round,
        [s.mobileSmall]: mobileSmall,
      })}
    >
      {src && (
        <img
          src={src}
          alt=""
          className={classnames(s.avatar, {
            [s.avatarLeftRound]: leftRound,
            [s.small]: small,
            [s.large]: large,
            [s.largeWrapper]: large,
            [s.round]: round,
            [s.mobileSmall]: mobileSmall,
          })}
        />
      )}
      {!src && <Icon width={small ? '60px' : undefined} name="not-user" />}
    </div>
  </React.Fragment>
);
