import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';

import { AreaChart, Area, Tooltip, ResponsiveContainer } from 'recharts';
import { ProfileGraphTooltip } from '../ProfileGraphTooltip/ProfileGraphTooltip';

import { apiGetChartData } from '../../api/get-chart-data';

import { IChartData, IProfileGraphData } from '../../typings/chart.type';

import s from './ProfileGraph.module.scss';

export const ProfileGraph: React.FC<{ id: string }> = ({ id }) => {
  const [chartContentData, setChartContentData] = useState<IChartData[]>([]);
  const [chartAnswersData, setChartAnswersData] = useState<IChartData[]>([]);
  const [chartAreaData, setChartAreaData] = useState<IProfileGraphData[]>([]);

  useEffect(() => {
    const getData = async (typeGroup: 'answers' | 'content', callback: (data: IChartData[]) => void) => {
      if (!id) {
        return;
      }

      const chartData = await apiGetChartData({
        params: { userId: id, dateFrom: moment().subtract(2, 'year').format('YYYY-MM-DD'), typeGroup },
      });

      if (!chartData) {
        return callback([]);
      }

      const chartDataOb: { [key: string]: number } = {};

      chartData.forEach((data) => {
        const m = moment(data.value).format('YYYY-MM');
        chartDataOb[m] = (chartDataOb[m] || 0) + data.count;
      });

      let startDate = moment().format('YYYY-MM-DD');
      const endDate = moment().subtract(2, 'year').subtract(1, 'month').format('YYYY-MM-DD');
      while (moment(startDate).isAfter(endDate)) {
        const index = moment(startDate).format('YYYY-MM');
        if (!chartDataOb[index]) {
          chartDataOb[index] = 0;
        }
        startDate = moment(startDate).subtract(1, 'month').format('YYYY-MM-DD');
      }

      const chart: IChartData[] = Object.keys(chartDataOb)
        .map((key: string) => ({
          value: `${key}-01`,
          count: chartDataOb[key],
        }))
        .sort((a, b) => (moment(a.value).isAfter(b.value) ? 1 : -1));

      callback(chart || []);
    };
    getData('answers', setChartAnswersData);
    getData('content', setChartContentData);
  }, [id]);

  useMemo(() => {
    const temp: IProfileGraphData[] = [];
    if (chartContentData.length && chartAnswersData.length) {
      chartContentData.forEach((item, index) => {
        temp.push({
          value: item.value,
          content: item.count,
          answers: chartAnswersData[index].count,
        });
      });
    }

    setChartAreaData(temp);
  }, [chartContentData, chartAnswersData]);

  return (
    <div className={s.profileGraphWrapper}>
      <span className={s.yAxis}>Last year activity</span>
      <div className={s.graphContainer}>
        {chartAreaData.length && (
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={chartAreaData}
              margin={{
                top: 3,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id="content" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="6%" stopColor="#1957C2" stopOpacity={0.9} />
                  <stop offset="94%" stopColor="#f4f7fc" stopOpacity={0.9} />
                </linearGradient>

                <linearGradient id="answers" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="6%" stopColor="#15C0CE" stopOpacity={0.9} />
                  <stop offset="94%" stopColor="#f4f7fc" stopOpacity={0.9} />
                </linearGradient>
              </defs>
              <Tooltip content={<ProfileGraphTooltip />} />
              <Area type="monotone" dataKey="content" strokeWidth={0} fillOpacity={1} fill="url(#content)" />
              <Area type="monotone" dataKey="answers" strokeWidth={0} fillOpacity={1} fill="url(#answers)" />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
      <div className={s.xAxis}>
        <span>{moment().subtract(2, 'year').format('MMMM YYYY')}</span>
        <span>{moment().format('MMMM YYYY')}</span>
      </div>
    </div>
  );
};
