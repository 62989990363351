import React from 'react';
import { TooltipProps } from 'recharts';
import moment from 'moment';

import s from './ProfileGraphTooltip.module.scss';

const answersTitle = 'User Forums:';
const contentTitle = 'Project Awareness:';

export const ProfileGraphTooltip: React.FC<TooltipProps<string[], 'count'>> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className={s.tooltipWrapper}>
        <span>{moment(payload[0].payload.value).format('MMMM YYYY')}</span>
        {payload[0].payload.answers ? (
          <span className={s.answers}>{`${answersTitle} ${payload[0].payload.answers}`}</span>
        ) : (
          <></>
        )}

        {payload[0].payload.content ? (
          <span className={s.content}>{`${contentTitle} ${payload[0].payload.content}`}</span>
        ) : (
          <></>
        )}
      </div>
    );
  }

  return null;
};
