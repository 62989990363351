import { httpGET } from '../../../common/utils/http';
import { IApiRequest } from '../../../common/typings/api.type';

import { API_LIST_CONTRIBUTIONS } from '../../../common/constants/api';

import { IContributionsList } from '../typings/contribution.type';

import { getProduct } from '../../../common/utils/product';
import {
  communitySupport,
  projectAwareness,
} from '../../profile/components/ProfileContributionsMenu/ProfileContributionsMenu';

export const apiGetDevContributions = async ({ params, options }: IApiRequest): Promise<IContributionsList | null> => {
  let types: { typeGroup?: string; type?: string } = {
    typeGroup: undefined,
    type: undefined,
  };

  if (params?.typeGroup) {
    types = {
      typeGroup: params.typeGroup.toLocaleString(),
      type: undefined,
    };
  }

  if (params?.type) {
    if (params?.type == communitySupport) {
      types = {
        typeGroup: 'answers',
        type: undefined,
      };
    }

    if (params?.type == projectAwareness) {
      types = {
        typeGroup: 'content',
        type: undefined,
      };
    }

    if (params?.type !== communitySupport && params?.type !== projectAwareness) {
      types = {
        typeGroup: undefined,
        type: params?.type.toString() || undefined,
      };
    }
  }

  return await httpGET<IContributionsList>({
    url: API_LIST_CONTRIBUTIONS,
    options,
    params: Object.assign(params, { product: getProduct() }, { ...types }),
  });
};
