import React from 'react';

import s from './Cooperate.module.scss';

export const Cooperate: React.FC = () => (
  <React.Fragment>
    <p className={s.cooperateText}>
      &copy;&nbsp;{new Date().getFullYear()} GridGain Systems<sup className="reg">&reg;</sup>, Inc. All Rights Reserved
    </p>
    <p className={s.cooperateText}>
      Apache Ignite
      <sup className="reg">&reg;</sup> and associated open source project names are trademarks of the Apache Software
      Foundation
    </p>
  </React.Fragment>
);
