import React from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import { URL_CONTRIBUTIONS } from '../../constants/url';
import { goToUrl } from '../../utils/create-url';

import s from './ListTags.module.scss';

interface IListTagsProps {
  onClick?: (tag: string) => void;
  tags: string[];
  blue?: boolean;
  blank?: boolean;
  className?: string;
}

export const ListTags: React.FC<IListTagsProps> = ({ onClick, tags, blue = false, blank = false, className }) => {
  const history = useHistory();
  return (
    <div className={classnames(className, s.listTags, { [s.blue]: blue })}>
      {tags.map((cur, index) => (
        <span
          onClick={(e) => {
            e.stopPropagation();
            if (onClick) {
              return onClick(cur);
            }
            if (!blank) {
              return history.push(`${URL_CONTRIBUTIONS}?tags[]=${cur}`);
            }
            goToUrl(`${URL_CONTRIBUTIONS}?tags[]=${cur}`);
          }}
          className={s.listTagItem}
          key={index}
        >
          {cur}
        </span>
      ))}
    </div>
  );
};
