import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import s from './ProfileContributionsMenu.module.scss';

import { Select } from '../../../../common/components/Select/Select';
import { createIntervalDate } from '../../../../common/utils/date-interval';

import { apiGetDevContributionsType } from '../../api/get-types';
import { IContributionType } from '../../../contributions/typings/contribution.type';

export const projectAwareness = 'Project Awareness';
export const communitySupport = `User Forums'`;
export const allContributions = 'All Contributions';

export const ProfileContributionsMenu: React.FC<{
  onChangeType: (type: string) => void;
  id: string;
  date: string;
  activeType: string;
}> = ({ onChangeType, id, activeType, date }) => {
  const [menu, setMenu] = useState<Array<{ value: string; count: number }>>([]);
  const [selectMenu, setSelectMenu] = useState<Array<{ value: string; label: string }>>([]);

  useEffect(() => {
    const getMenu = async () => {
      if (id) {
        console.log(date);
        const menuContent = await apiGetDevContributionsType({
          params: { userId: id, ...createIntervalDate(date, 'dateTo'), typeGroup: 'content' },
        });

        const menuAnswers = await apiGetDevContributionsType({
          params: { userId: id, ...createIntervalDate(date, 'dateTo'), typeGroup: 'answers' },
        });

        const onCreateMenuList = (menu: IContributionType | null, textOfTitle: string) => {
          let all = 0;
          const selMenu: Array<{ value: string; label: string }> = [];

          if (menu) {
            menu.forEach((item) => {
              all += item.count;
              selMenu.push({ value: item.value, label: `${item.value} (${item.count})` });
            });
            if (all !== 0) {
              menu.unshift({ value: textOfTitle, count: all });
              selMenu.unshift({ value: textOfTitle, label: ` - ${textOfTitle} (${all})` });
            }
          }

          return {
            menu,
            selMenu,
          };
        };

        const { menu: menuCnt, selMenu: selMenuCnt } = onCreateMenuList(menuContent, projectAwareness);
        const { menu: menuAns, selMenu: selMenuAns } = onCreateMenuList(menuAnswers, communitySupport);

        setMenu(menuCnt?.concat(menuAns || []) || []);
        setSelectMenu(selMenuCnt.concat(selMenuAns));
      }
    };
    getMenu();
  }, [id, date]);

  return (
    <div className={s.menuWrapper}>
      {menu && (
        <div className={s.mobileMenu}>
          <Select value={activeType} onChange={onChangeType} options={selectMenu} />
        </div>
      )}
      {menu && (
        <ul className={s.menu}>
          {menu.map((current, index) => (
            <li
              onClick={(e) => {
                e.preventDefault();
                onChangeType(current.value);
              }}
              className={classnames(
                s.menuItem,
                { [s.menuItemActive]: activeType === current.value },
                current.value === communitySupport ? s.topBorder : '',
              )}
              key={index}
            >
              {current.value} ({current.count})
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
