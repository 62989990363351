import React from 'react';
import classnames from 'classnames';
import queryString from 'query-string';
import { Link } from 'react-router-dom';

import { Avatar } from '../Avatar/Avatar';
import { Icon } from '../Icon/Icon';

import { URL_PROFILE } from '../../constants/url';

import s from './UserInfo.module.scss';

interface IUserInfo {
  src: string | null;
  name: string | null;
  id: string;
  text?: string;
  urlParams?: { [key: string]: string };
  totalCount?: number | null;
  count?: number | null;
  leftRound?: boolean;
  round?: boolean;
  countDisabled?: boolean;
  mobileView?: boolean;
  target?: '_self' | '_blank';
}

export const UserInfo: React.FC<IUserInfo> = ({
  round = false,
  leftRound = false,
  countDisabled = false,
  src,
  id,
  target,
  name,
  totalCount,
  count,
  text = '',
  mobileView = false,
  urlParams,
}) => (
  <div className={classnames(s.userInfoWrapper, { [s.mobileView]: mobileView })}>
    <div className={s.userInfoAvatar}>
      <Avatar round={round} leftRound={leftRound} src={src || ''} />
    </div>
    <div className={s.userInfo}>
      {target === '_blank' ? (
        <a
          href={`${URL_PROFILE.replace(':id', id)}${urlParams ? `?${queryString.stringify(urlParams)}` : ''}`}
          target="_blank"
          rel="noreferrer"
        >
          <div className={s.userInfoName}>{name}</div>
        </a>
      ) : (
        <Link to={`${URL_PROFILE.replace(':id', id)}${urlParams ? `?${queryString.stringify(urlParams)}` : ''}`}>
          <div className={s.userInfoName}>{name}</div>
        </Link>
      )}
      {!countDisabled && !text && (
        <div className={s.userInfoScore}>
          <Icon name="star" />
          <span className={s.userInfoTotalCount}>
            {count} of {totalCount}
          </span>
        </div>
      )}
      {!countDisabled && text && <div className={s.userInfoScore}>{text}</div>}
    </div>
  </div>
);
