import React from 'react';

import { Spin } from 'antd';

import s from './Spinner.module.scss';

export const Spinner: React.FC<{ type?: 'table' | 'btn' }> = ({ type = 'table' }) => (
  <React.Fragment>
    {type === 'table' && (
      <div className={s.spinnerWrapper}>
        <Spin size="large" />
      </div>
    )}
    {type === 'btn' && (
      <span className={s.spinnerBtn}>
        <Spin size="small" />
      </span>
    )}
  </React.Fragment>
);
