import React from 'react';

import s from './ProfileContact.module.scss';

export const ProfileContact: React.FC<{ github?: string; email?: string; stackoverflow?: string }> = ({
  github,
  stackoverflow,
}) => (
  <div className={s.profileBottomContentLeft}>
    {github && (
      <div className={s.profileBottomContentLeftItem}>
        <div className={s.profileBottomContentLeftItemTitle}>Github</div>
        <div className={s.profileBottomContentLeftItemText}>
          <a target="_blank" rel="noreferrer" href={`https://github.com/${github}`}>
            {github}
          </a>
        </div>
      </div>
    )}
    {stackoverflow && (
      <div className={s.profileBottomContentLeftItem}>
        <div className={s.profileBottomContentLeftItemTitle}>StackOverflow</div>
        <div className={s.profileBottomContentLeftItemText}>
          <a target="_blank" rel="noreferrer" href={`https://stackoverflow.com/users/${stackoverflow}`}>
            View Profile
          </a>
        </div>
      </div>
    )}
    {/* {email && (
      <div className={s.profileBottomContentLeftItem}>
        <div className={s.profileBottomContentLeftItemTitle}>Email</div>
        <div className={s.profileBottomContentLeftItemText}>
          <a target="_blank" rel="noreferrer" href={`mailto:${email}`}>
            {email}
          </a>
        </div>
      </div>
    )} */}
  </div>
);
