import React, { useState, useEffect } from 'react';
import { IContributionsFilter } from '../../typings/contribution.type';
import { TableSearch } from '../../../../common/components/TableSearch/TableSearch';
import { Select } from '../../../../common/components/Select/Select';
import { optionsSelect } from '../../../../common/utils/date-interval';

import { apiGetDevContributionsType } from '../../../profile/api/get-types';
import { Switcher, SwitcherValues } from '../../../../common/components/Switcher/Switcher';

import { ReactComponent as AwarenessIcon } from '../../../../assets/images/icons/rupor.svg';
import { ReactComponent as CommunityIcon } from '../../../../assets/images/icons/community.svg';

import s from './ContributionsFilter.module.scss';
import { getProduct } from '../../../../common/utils/product';

interface IContributionsFilterProps {
  value: IContributionsFilter;
  setFilter: (valOb: { [key: string]: string }) => void;
}

export const ContributionsFilter: React.FC<IContributionsFilterProps> = ({ value, setFilter }) => {
  const [optionsType, setOptionsType] = useState<Array<{ label: string; value: string }>>([]);

  useEffect(() => {
    const getOptionsType = async () => {
      const optionsType = await apiGetDevContributionsType({ params: { typeGroup: value.typeGroup } });
      if (optionsType) {
        const newOptionsType = [];
        let all = 0;
        optionsType.forEach((item) => {
          all += item.count;

          newOptionsType.push({
            label: `${item.value.charAt(0).toUpperCase() + item.value.slice(1).toLowerCase()} (${item.count})`,
            value: item.value,
          });
        });
        newOptionsType.unshift({ label: `All types (${all})`, value: 'allTypes' });
        setOptionsType(newOptionsType);
      }
    };
    getOptionsType();
  }, [value]);

  const switcherValues: SwitcherValues[] = [
    { icon: <AwarenessIcon />, text: 'Project Awareness', key: 'content' },
    { icon: <CommunityIcon />, text: 'User Forums', key: 'answers' },
  ];

  const isShowSwitcher = getProduct() !== 'gridgain';

  return (
    <div className={s.wrapperFilter}>
      {isShowSwitcher ? (
        <Switcher
          className={s.switcher}
          defaultValue={value.typeGroup}
          values={switcherValues}
          onChange={(val) => setFilter({ typeGroup: val, type: '' })}
        />
      ) : (
        <span />
      )}
      <Select
        onChange={(val) => {
          setFilter({ type: val === 'allTypes' ? '' : val });
        }}
        value={value.type || 'allTypes'}
        options={optionsType}
      />
      <Select
        type="contributionsIntreval"
        onChange={(val) => {
          setFilter({ date: val });
        }}
        value={value.date}
        options={optionsSelect}
      />
      <TableSearch
        value={value.searchQuery}
        placeholder={'Search by a contribution title'}
        onChange={(valNew) => {
          setFilter({ searchQuery: valNew });
        }}
      />
    </div>
  );
};
