import { URL_LIDERBOARD, URL_CONTRIBUTIONS, URL_ABOUT } from './url';

export const MENU = [
  {
    url: URL_LIDERBOARD,
    title: 'Contributors',
    icon: 'star',
  },
  {
    url: URL_CONTRIBUTIONS,
    title: 'Contributions',
    icon: '',
  },
  {
    url: URL_ABOUT,
    title: 'About',
    icon: '',
  },
];
