import React from 'react';
import moment from 'moment';

import { IContributionsList } from '../../../contributions/typings/contribution.type';

import { Table } from '../../../../common/components/Table/Table';
import { TableRow } from '../../../../common/components/TableRow/TableRow';
import { TableCell } from '../../../../common/components/TableCell/TableCell';
import { TableCellTitle } from '../../../../common/components/TableCellTitle/TableCellTitle';

import { ListTags } from '../../../../common/components/ListTags/ListTags';

import s from './ProfileContributionsTable.module.scss';

const th = [
  {
    title: 'Title',
  },
  {
    title: 'Type',
    mobileDisabled: true,
  },
  {
    title: 'Date',
    mobileDisabled: true,
  },
  // {
  //   title: 'Reputation',
  // },
  {
    title: 'Tags',
    mobileDisabled: true,
    tabletDisabled: true,
  },
];

export const ProfileContributionsTable: React.FC<{
  loadingLoadMore?: boolean;
  nextPage?: () => void;
  serachTag?: (tag: string) => void;
  loading?: boolean;
  listData: IContributionsList;
}> = ({ nextPage, listData, loading = true, loadingLoadMore = false }) => (
  <div className={s.profileContributionsTable}>
    <Table
      showLoadMore={!(listData && listData.data.length === listData.count)}
      loadingLoadMore={loadingLoadMore}
      nextPage={nextPage}
      loading={loading}
      columns={th}
    >
      {listData.data.map((current) => (
        <TableRow key={current.id + current.date}>
          <TableCell style={{ width: '310px' }}>
            <TableCellTitle
              type={current.type.name}
              date={moment(current.date).format('DD MMM, YYYY')}
              url={current.url}
            >
              {current.title}
            </TableCellTitle>
          </TableCell>
          <TableCell mobileDisabled={true}>{current.type.name}</TableCell>
          <TableCell mobileDisabled={true}>{moment(current.date).format('DD MMM, YYYY')}</TableCell>
          {/* <TableCell>{current.type.score}</TableCell> */}
          <TableCell mobileDisabled={true} tabletDisabled={true}>
            <ListTags tags={current.tags || []} blue />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  </div>
);
