import React from 'react';
import classnames from 'classnames';

import s from './TableCell.module.scss';

interface ITableCell {
  type?: 'td' | 'th';
  children?: React.ReactNode;
  style?: React.CSSProperties;
  mobileDisabled?: boolean;
  tabletDisabled?: boolean;
}

export const TableCell: React.FC<ITableCell> = ({
  mobileDisabled = false,
  tabletDisabled = false,
  style,
  children,
  type = 'td',
}) => (
  <React.Fragment>
    {type === 'td' && (
      <td
        style={style}
        className={classnames(s.tableCell, {
          [s.mobileDisabled]: mobileDisabled,
          [s.tabletDisabled]: tabletDisabled,
        })}
      >
        {children}
      </td>
    )}
    {type === 'th' && (
      <th
        style={style}
        className={classnames(s.tableCell, s.tableCellTh, {
          [s.mobileDisabled]: mobileDisabled,
          [s.tabletDisabled]: tabletDisabled,
        })}
      >
        {children}
      </th>
    )}
  </React.Fragment>
);
