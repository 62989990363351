import React from 'react';
import moment from 'moment';

import { View } from '../../../../common/components/View/View';
import { Table } from '../../../../common/components/Table/Table';
import { TableRow } from '../../../../common/components/TableRow/TableRow';
import { TableCell } from '../../../../common/components/TableCell/TableCell';
import { TableCellTitle } from '../../../../common/components/TableCellTitle/TableCellTitle';
import { ListTags } from '../../../../common/components/ListTags/ListTags';

import { UserInfo } from '../../../../common/components/UserInfo/UserInfo';
// import { UserScore } from '../../../../common/components/UserScore/UserScore';
import { useTable } from '../../../../common/hooks/useTable';

import { IContribution, IContributionsFilter } from '../../typings/contribution.type';

import { ContributionsFilter } from '../ContributionsFilter/ContributionsFilter';

import { apiGetDevContributions } from '../../api/get-dev-contributions';
import {
  communitySupport,
  projectAwareness,
} from '../../../profile/components/ProfileContributionsMenu/ProfileContributionsMenu';

const th = [
  {
    title: 'Title',
  },
  {
    title: 'Type',
    mobileDisabled: true,
  },
  {
    title: 'Date',
    mobileDisabled: true,
  },
  // {
  //   title: 'Reputation',
  // },
  {
    title: 'Tags',
    mobileDisabled: true,
    tabletDisabled: true,
  },
  {
    title: 'Contributor',
  },
];

const description = ``;

export const ContributionsTable: React.FC = () => {
  const { listData, filterData, loading, loadingLoadMore, setFilter, nextPage, serachTag } = useTable<
    IContribution,
    IContributionsFilter
  >(
    apiGetDevContributions,
    {
      type: '',
      searchQuery: '',
      dateFrom: '',
      dateTo: '',
      date: 'alltime',
      typeGroup: 'content',
    },
    undefined,
    { sortBy: 'score', sort: 'DESC' },
    true,
    'dateTo',
  );

  const title = `${filterData.typeGroup === 'content' ? projectAwareness : communitySupport} Contributions`;

  return (
    <View
      loading={loading}
      loadingLoadMore={loadingLoadMore}
      loadingLoadShow={!(listData && listData.data.length === listData.count)}
      nextPage={nextPage}
      type="head"
      componentSubHeader={
        <ContributionsFilter
          setFilter={(valOb) => {
            setFilter(null, undefined, valOb);
          }}
          value={filterData}
        />
      }
      title={title}
      description={description}
    >
      <Table loading={false} columns={th}>
        {listData.data.map((current, index) => (
          <TableRow key={current.date + Math.random()}>
            <TableCell style={{ width: '310px' }}>
              <TableCellTitle
                type={current.type.name}
                date={moment(current.date).format('DD MMM, YYYY')}
                url={current.url}
              >
                {current.title}
              </TableCellTitle>
            </TableCell>
            <TableCell mobileDisabled={true}>{current.type.name}</TableCell>
            <TableCell mobileDisabled={true}>{moment(current.date).format('DD MMM, YYYY')}</TableCell>
            {/* <TableCell>
              <UserScore score={current.type.score} />
            </TableCell> */}
            <TableCell mobileDisabled={true} tabletDisabled={true}>
              <ListTags tags={current.tags || []} blue onClick={serachTag} />
            </TableCell>
            <TableCell style={{ width: '200px' }}>
              <UserInfo
                round
                countDisabled
                count={index + 1}
                totalCount={listData.totalCount}
                id={current.user.id}
                src={current.user.avatarUrl}
                name={current.user.name}
                mobileView={true}
              />
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </View>
  );
};
