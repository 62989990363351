import React from 'react';

import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { View } from '../../../../common/components/View/View';
import { List } from '../../../../common/components/List/List';
import { ListRow } from '../../../../common/components/ListRow/ListRow';
import { ListCell } from '../../../../common/components/ListCell/ListCell';
// import { WidgetUserInfo } from '../../../../common/components/WidgetUserInfo/WidgetUserInfo';
import { ListTags } from '../../../../common/components/ListTags/ListTags';
import { UserInfo } from '../../../../common/components/UserInfo/UserInfo';
import { UserScore } from '../../../../common/components/UserScore/UserScore';

import { useTable } from '../../../../common/hooks/useTable';
import { createIntervalDate } from '../../../../common/utils/date-interval';

import { IDevs } from '../../typings/devs.type';

import { apiDevsList } from '../../api/get-devs-list';

import { URL_PROFILE } from '../../../../common/constants/url';

const title = 'Project Awareness Top Contributers';
const description = `About. Apache Ignite Monitoring With Control Center. Last quarter Top-10`;

export const LeaderboardWidgetTable: React.FC = () => {
  const location = useLocation();
  const paramsData = queryString.parse(location.search, { arrayFormat: 'bracket' });
  const filterInterval =
    paramsData.interval && paramsData.interval !== 'alltime' ? createIntervalDate(paramsData.interval as string) : {};
  const product: { [key: string]: string } = paramsData.product ? { product: paramsData.product as string } : {};

  const filter = Object.assign({}, filterInterval, product);

  const { listData, loading } = useTable<IDevs, { [key: string]: string }>(apiDevsList, {}, undefined, {
    sortBy: 'score',
    sort: 'DESC',
    typeGroup: paramsData.typeGroup === 'answers' ? 'answers' : 'content',
    limit: paramsData.limit,
    ...filter,
  });

  return (
    <View loadingLoadShow={false} loading={loading} type="widget" title={title} description={description}>
      <List>
        {listData &&
          listData.data.map((item, index) => {
            if (item.contributions.score !== 0) {
              return (
                <ListRow key={index} cursor link="_blank" linkTo={`${URL_PROFILE.replace(':id', item.id)}`}>
                  <ListCell>
                    <UserInfo
                      src={item.avatarUrl}
                      leftRound
                      target="_blank"
                      name={item.name}
                      id={item.id}
                      count={index + 1}
                      totalCount={listData.totalCount}
                    />
                  </ListCell>

                  <ListCell mobileDisabled>
                    <ListTags tags={item.contributions.tags} blank />
                  </ListCell>

                  <ListCell mobileSmall style={{ textAlign: 'center' }}>
                    <UserScore score={item.contributions.score} />
                  </ListCell>
                </ListRow>
              );
            }
          })}
      </List>
    </View>
  );
};
