import { getItem, setItem } from './local-storage';

import {
  PRODUCT_KEY,
  PRODUCT_CHOOSER_KEY,
  GRIDGAIN_PRODUCT,
  IGNITE_PRODUCT,
  DEFAULT_PRODUCT,
} from '../constants/product';

export const changeProduct = (): void => {
  const searchParams = new URL(window.location.href).searchParams;
  const product = searchParams.get(PRODUCT_KEY);
  if (product) setItem(PRODUCT_KEY, product.toString().trim());
};

export const getProductName = (product: string): string => {
  if (product === IGNITE_PRODUCT) return 'Apache Ignite';
  if (product === GRIDGAIN_PRODUCT) return 'GridGain Systems';
  return product;
};

export const setProductName = (product: string): void => setItem(PRODUCT_KEY, product);

export const getProduct = (): string => getItem(PRODUCT_KEY)?.toString() || DEFAULT_PRODUCT;

export const checkProductGridgain = (key: string, product: string): boolean => {
  if (key === PRODUCT_KEY && product === GRIDGAIN_PRODUCT) {
    setItem(PRODUCT_CHOOSER_KEY, 'true');
  }
  return true;
};

export const getProductChooser = (): boolean => getItem(PRODUCT_CHOOSER_KEY)?.toString() === 'true';

export const getProductByUrl = (): string => {
  const searchParams = new URL(window.location.href).searchParams;
  const product = searchParams.get(PRODUCT_KEY);
  const localParams = getItem(PRODUCT_KEY);

  return product || localParams?.toString() || DEFAULT_PRODUCT;
};

export const getProductChooserByUrl = (): boolean => {
  const searchParams = new URL(window.location.href).searchParams;
  const product = searchParams.get(PRODUCT_KEY);
  const localParams = getItem(PRODUCT_CHOOSER_KEY);

  return product === GRIDGAIN_PRODUCT || !!localParams === true || false;
};
