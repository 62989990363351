import React from 'react';

import s from './TitleTableView.module.scss';

interface ITitleTableView {
  title: string;
  description?: string;
}

export const TitleTableView: React.FC<ITitleTableView> = ({ title, description = '' }) => (
  <div className={s.titleTableWrapper}>
    <h1 className={s.titleTable}>{title}</h1>
    <div className={s.descriptionTable}>{description}</div>
  </div>
);
