import queryString from 'query-string';

import { IObject } from '../typings/object.type';

export const createUrl = (url: string, params?: IObject): string => {
  let newUrl = url;

  if (params) {
    Object.keys(params).map((key) => {
      newUrl = newUrl.replace(`:${key}`, params[key]?.toString() || '');
    });
  }

  return newUrl;
};

export const isValidHttpUrl = (str: string): boolean => {
  let url;

  try {
    url = new URL(str);
  } catch (e) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const goToUrlNewProduct = (product: string): void => {
  const urlOld = queryString.parse(window.location.search);
  const main = window.location.origin + window.location.pathname;

  window.location.href = queryString.stringifyUrl({
    url: main,
    query: Object.assign({}, urlOld, { product: product }),
  });
};

export const goToUrl = (url: string): void => {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.style.visibility = 'hidden';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
