import { httpGET } from '../../../common/utils/http';
import { IApiRequest } from '../../../common/typings/api.type';

import { API_LIST_USER_TYPES } from '../../../common/constants/api';

import { IContributionType } from '../../contributions/typings/contribution.type';
import { getProduct } from '../../../common/utils/product';

export const apiGetDevContributionsType = async ({ params }: IApiRequest): Promise<IContributionType | null> => {
  if (!params) params = {};

  params[`product`] = getProduct();
  params['typeGroup'] = params?.typeGroup || 'content';

  return await httpGET<IContributionType>({ url: API_LIST_USER_TYPES, params: params });
};
