import React, { useEffect, useState } from 'react';

import styles from './Switcher.module.scss';

export interface SwitcherValues {
  text: string;
  key: string;
  icon: JSX.Element | string;
}

interface IProps {
  className?: string;
  defaultValue?: string | null;
  values: SwitcherValues[];
  onChange: (value: string) => void;
}

export const Switcher = ({ className, values, defaultValue, onChange }: IProps): JSX.Element => {
  const [selectedItem, setSelectedItem] = useState<string>('');

  useEffect(() => {
    if (!defaultValue) {
      setSelectedItem(values[0].key);
    } else {
      setSelectedItem(defaultValue);
    }
  }, [defaultValue, values]);

  const onSetSelectedItem = (key: string) => {
    if (selectedItem !== key) {
      setSelectedItem(key);
      onChange(key);
    }
  };

  const getBackgroundItemColor = (key: string) => (key === selectedItem ? styles.selectedItem : styles.switcherItem);

  return (
    <div className={`${styles.SwitcherWrapper} ${className}`}>
      {values.map((item) => (
        <div key={item.key} className={getBackgroundItemColor(item.key)} onClick={() => onSetSelectedItem(item.key)}>
          <span className={styles.icon}>{item.icon}</span>
          <span>{item.text}</span>
        </div>
      ))}
    </div>
  );
};
