import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'antd';

import s from './Logo.module.scss';

import { GRIDGAIN_PRODUCT } from '../../constants/product';
import { getProductName } from '../../utils/product';

import { useGlobalContext } from '../../context/ProductContext';

import { MenuChooseProduct } from '../MenuChooseProduct/MenuChooseProduct';
import { Icon } from '../Icon/Icon';

import { ReactComponent as LogoImage } from '../../../assets/images/gridgain-logo-2021.svg';

export const Logo: React.FC = () => {
  const { product, productChooser } = useGlobalContext();
  return (
    <React.Fragment>
      {product === GRIDGAIN_PRODUCT && (
        <div className={s.wrapperLogo}>
          <Link to="/" className={s.logoImage}>
            <LogoImage />
          </Link>
          <div className={s.logo}>
            <Dropdown className={s.logoTopTextLink} overlay={<MenuChooseProduct />} trigger={['click']}>
              <div className={s.logoTopText}>
                {getProductName(product)} <Icon name="down-arrow" />
              </div>
            </Dropdown>
            <div className={s.logoBottomText}>Contributors Leaderboard</div>
          </div>
        </div>
      )}
      {product !== GRIDGAIN_PRODUCT && (
        <div className={s.logo}>
          {!productChooser && (
            <Link to="/">
              <div className={s.logoTopText}>{getProductName(product)}</div>
            </Link>
          )}
          {productChooser && (
            <Dropdown overlay={<MenuChooseProduct />} className={s.logoTopTextLink} trigger={['click']}>
              <div className={s.logoTopText}>
                {getProductName(product)} <Icon name="down-arrow" />
              </div>
            </Dropdown>
          )}
          <div className={s.logoBottomText}>Non-code Contributions</div>
        </div>
      )}
    </React.Fragment>
  );
};
