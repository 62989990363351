import React from 'react';
import classnames from 'classnames';

import s from './Hamburger.module.scss';

export const Hamburger: React.FC<{ openMenu: boolean; setOpenMenu: () => void }> = ({
  openMenu = false,
  setOpenMenu,
}) => (
  <div
    className={classnames(s.navMobileOpen, { [s.openMenu]: openMenu })}
    onClick={(e) => {
      e.preventDefault();
      setOpenMenu();
    }}
  >
    <span></span>
    <span></span>
  </div>
);
