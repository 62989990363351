import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import s from './ProfilePage.module.scss';

import { ProfileHeader } from '../components/ProfileHeader/ProfileHeader';
import { ProfileGraph } from '../components/ProfileGraph/ProfileGraph';
import { ProfileContributions } from '../components/ProfileContributions/ProfileContributions';

import { IDevs, ISummary } from '../../leaderboard/typings/devs.type';

import { apiGetDev } from '../api/get-dev';

export const ProfilePage: React.FC = () => {
  const [user, setUser] = useState<IDevs | null>(null);
  const [contentSummary, setContentSummary] = useState<ISummary>();
  const [answersSummary, setAnswersSummary] = useState<ISummary>();
  const params = useParams<{ id: string }>();

  useEffect(() => {
    const getUser = async () => {
      if (params.id) {
        const user = await apiGetDev({ options: { id: params.id }, params: { typeGroup: 'content' } });
        if (user) {
          setUser(user);
          setContentSummary(user.summary);
        }
        const userAnswersSummary = await apiGetDev({ options: { id: params.id }, params: { typeGroup: 'answers' } });
        if (userAnswersSummary) {
          setAnswersSummary(userAnswersSummary.summary);
        }
      }
    };
    getUser();
  }, [params.id]);

  return (
    <div className={s.wrapperProfile}>
      <ProfileHeader user={user} contentSummary={contentSummary} answersSummary={answersSummary} />
      <ProfileGraph id={params.id} />
      <ProfileContributions id={params.id} />
    </div>
  );
};
