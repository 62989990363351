import queryString from 'query-string';

import { useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useDebounce } from '../hooks/useDebounced';

import { ISortList, IPageList, IList } from '../typings/list.type';
import { IApiFn } from '../typings/api.type';
import { IObject } from '../typings/object.type';
import { ITableCreateProps } from '../typings/table-function.type';

import { COUNT_ROWS_LIST } from '../constants/list';

import { createIntervalDate } from '../utils/date-interval';

import {
  tableCreateFilter,
  tableCreateSort,
  tableCreatePage,
  createApiParams,
  tableResetPage,
} from '../utils/table-function';

interface IUseTableReturn<T, F> {
  listData: IList<T>;
  filterData: F;
  sortData: ISortList;
  pageData: IPageList;
  loading: boolean;
  loadingLoadMore: boolean;
  setSort: (sort: ISortList) => void;
  setFilter: ITableCreateProps<F>;
  nextPage: () => void;
  resetFilter: () => void;
  refreshData: () => void;
  serachTag: (tag: string) => void;
}

export const useTable = <T, F extends IObject>(
  api: IApiFn<IList<T>>,
  filterInit: F,
  defaultSort?: ISortList,
  apiParams?: IObject,
  urlCreateFlag?: boolean,
  filterDateFormat?: 'dateTo' | 'contributions',
): IUseTableReturn<T, F> => {
  const location = useLocation();

  const urlParams = queryString.parse(location.search, { arrayFormat: 'bracket' });

  const [listData, setListData] = useState<IList<T>>({ count: 0, totalCount: 0, data: [] });
  const [loading, setLoading] = useState(true);
  const [changePage, setChangePage] = useState(false);
  const [waitReloadData, setWaitReloadData] = useState(false);

  const [sortData, setSortData] = useState<ISortList>({
    column: urlParams.sortBy ? urlParams.sortBy.toString() : defaultSort?.column || null,
    dir:
      urlParams.sortBy && (urlParams.sort === 'DESC' || urlParams.sort === 'ASC')
        ? urlParams.sort
        : defaultSort?.dir || 'DESC',
  });

  const [pageData, setPageData] = useState<IPageList>({
    offset: 0,
    limit: COUNT_ROWS_LIST,
  });

  const filterUrlInit: IObject = {};
  Object.keys(urlParams).forEach((param) => {
    if (filterInit.searchQuery !== undefined && param === 'tags' && Array.isArray(urlParams[param])) {
      const tag = urlParams[param] as string[];
      filterUrlInit.searchQuery = `tag:${tag[0]}`;
    }

    if (param && param in filterInit) {
      filterUrlInit[param] = urlParams[param];
    }
  });

  const [filterData, setFilterData] = useState<F>(Object.assign({}, filterInit, filterUrlInit));
  const debouncedFilter = useDebounce<F>(filterData, 300);

  useEffect(() => {
    //FIXME: Убрать ссылочность
    const fetchList = async () => {
      if (waitReloadData) {
        setWaitReloadData(false);
        return;
      }
      if (!changePage) setLoading(true);
      const params = createApiParams<F>(sortData, pageData, debouncedFilter);

      const data = await api({
        params: Object.assign(
          params.api,
          apiParams || {},
          { date: undefined },
          {
            ...createIntervalDate(
              params.api.date?.toString() || '',
              filterDateFormat?.toString() === 'dateTo' ? 'dateTo' : undefined,
            ),
          },
        ),
      });

      if (urlCreateFlag) {
        // Убираем лишний рендер
        window.history.pushState(null, '', `${location.pathname}${params.url && '?'}${params.url}`);
      }

      setLoading(false);
      if (data) {
        if (changePage) {
          setListData({
            withCountributionsCount: data.withCountributionsCount,
            count: data.count,
            totalCount: data.totalCount,
            data: listData.data.concat(data.data),
          });
        } else {
          setListData(data);
        }
      }
      setChangePage(false);
      // FIXME: логика ошибок
    };

    fetchList();
    // eslint-disable-next-line
  }, [sortData, pageData, debouncedFilter, location.pathname]);

  const setSort = useCallback(
    (newSort) => {
      setSortData(tableCreateSort(newSort, sortData));
    },
    [sortData],
  );

  const setFilter = useCallback(
    (e, name, val) => {
      setWaitReloadData(true);
      setPageData(tableResetPage());
      setFilterData(tableCreateFilter(e, name, val, filterData));
    },
    [filterData],
  );

  const nextPage = useCallback(() => {
    setPageData(tableCreatePage(pageData));
    setChangePage(true);
  }, [pageData]);

  const resetFilter = useCallback(() => {
    setFilterData(filterInit);
  }, [filterInit]);

  const refreshData = useCallback(() => {
    setFilterData(filterData);
  }, [filterData]);

  const serachTag = useCallback(
    (tag) => {
      if (filterInit.searchQuery !== undefined) {
        setFilter(null, 'searchQuery', `tag:${tag}`);
      }
    },
    [filterInit.searchQuery, setFilter],
  );

  return {
    listData,
    sortData,
    filterData,
    pageData,
    loading,
    loadingLoadMore: changePage,
    setSort,
    setFilter,
    nextPage,
    resetFilter,
    refreshData,
    serachTag,
  };
};
