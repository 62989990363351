import { httpGET } from '../../../common/utils/http';
import { IApiRequest } from '../../../common/typings/api.type';

import { API_GET_USER_CHART_DATA } from '../../../common/constants/api';

import { IChartData } from '../typings/chart.type';

import { getProduct } from '../../../common/utils/product';

export const apiGetChartData = async ({ options, params }: IApiRequest): Promise<IChartData[] | null> =>
  await httpGET<IChartData[]>({
    url: API_GET_USER_CHART_DATA,
    options,
    params: Object.assign({}, params, { product: getProduct() }),
  });
