import React from 'react';

import { ILayout } from '../typings/layout.type';

import { FooterGuest } from '../components/FooterGuest/FooterGuest';
import { HeaderGuest } from '../components/HeaderGuest/HeaderGuest';
import { MainContentGuest } from '../components/MainContentGuest/MainContentGuest';

export const GuestLayout: React.FC = ({ children }: ILayout) => (
  <React.Fragment>
    <HeaderGuest />
    <MainContentGuest>{children}</MainContentGuest>
    <FooterGuest />
  </React.Fragment>
);
