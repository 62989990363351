import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Helmet from 'react-helmet';

import './App.scss';

import { RouterProvider } from '../router/RouterProvider';

import { ProductContext } from '../common/context/ProductContext';
import { getProductByUrl, getProductChooserByUrl } from '../common/utils/product';

export const App: React.FC = () => {
  const [product, setProduct] = useState<string>(getProductByUrl());
  const [productChooser, setProductChooser] = useState<boolean>(getProductChooserByUrl());

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{ lang: 'en', amp: undefined }}
        titleTemplate={`%s | Contributors Leaderboard`}
        defaultTitle={`Contributors Leaderboard`}
      />
      <Router>
        <ProductContext.Provider value={{ productChooser, setProductChooser, product, setProduct }}>
          <RouterProvider />
        </ProductContext.Provider>
      </Router>
    </React.Fragment>
  );
};
