import React from 'react';
import classnames from 'classnames';

import s from './ListCell.module.scss';

interface IListCell {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  mobileDisabled?: boolean;
  mobileSmall?: boolean;
}

export const ListCell: React.FC<IListCell> = ({ children, style, mobileDisabled = false, mobileSmall = false }) => (
  <div
    className={classnames(s.listCell, { [s.mobileDisabled]: mobileDisabled, [s.mobileSmall]: mobileSmall })}
    style={style || {}}
  >
    {children}
  </div>
);
