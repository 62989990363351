import React from 'react';
import classnames from 'classnames';

import { Icon } from '../Icon/Icon';

import s from './UserScore.module.scss';

export const UserScore: React.FC<{ score: number | string; light?: boolean }> = ({ score, light }) => (
  <div className={classnames(s.scoreWrapper, { [s.light]: light })}>
    <Icon name="speed" />
    <span className={classnames(s.scoreText)}>{score}</span>
  </div>
);
