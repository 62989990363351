import React, { useState, useRef } from 'react';
import classnames from 'classnames';

import { Icon } from '../Icon/Icon';
import { Input } from '../Input/Input';
import { Input as AntInput } from 'antd';

import s from './TableSearch.module.scss';

interface ITableSearch {
  value: string;
  placeholder: string;
  onChange: (val: string) => void;
}

export const TableSearch: React.FC<ITableSearch> = ({ placeholder, value, onChange }) => {
  const [openSearch, setOpenSearch] = useState(value.length !== 0);
  const inputRef = useRef<AntInput>(null);

  return (
    <div
      onClick={() => {
        if (!openSearch) setOpenSearch(!openSearch);
      }}
      className={classnames(s.wrapperSearch, { [s.wrapperSearchOpen]: openSearch || value.length !== 0 })}
    >
      <span className={s.wrapperSearchInput}>
        <Input
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.value);
          }}
          placeholder={placeholder}
          inputRef={inputRef}
        />
      </span>
      <span
        className={s.searchIcon}
        onClick={() => {
          if (value.length === 0) {
            if (inputRef.current) {
              inputRef.current.focus();
            }
            if (!openSearch) setOpenSearch(!openSearch);
          }

          if (value.length !== 0) {
            onChange('');
          }
        }}
      >
        {value.length === 0 && <Icon name="search" />}
        {value.length !== 0 && <Icon name="close" />}
      </span>
    </div>
  );
};
