import queryString from 'query-string';

import { ISortList, IPageList } from '../typings/list.type';
import { IApiParamsObject } from '../typings/api.type';
import { IObject } from '../typings/object.type';
import { ITableCreateFilter } from '../typings/table-function.type';

import { COUNT_ROWS_LIST } from '../constants/list';

export const tableCreateFilter: ITableCreateFilter = (e = null, name, val = '', oldState) => {
  if (val !== null && typeof val === 'object') {
    return Object.assign({}, oldState, val);
  }

  if (name || (e && e.target.name)) {
    const n = name ? name : e !== null ? e.target.name : '';
    const v = val ? val : e !== null ? e.target.value : '';
    return Object.assign({}, oldState, { [n]: v });
  }

  return oldState;
};

export const tableCreateSort = (newSort: ISortList, oldSort: ISortList): ISortList => {
  if (oldSort.column) {
    return {
      column: newSort.column,
      dir: newSort.dir,
    };
  }

  return { column: newSort.column, dir: newSort.dir };
};

export const tableCreatePage = (pageData: IPageList): IPageList => ({
  offset: pageData.offset + COUNT_ROWS_LIST,
  limit: pageData.limit,
});

export const tableResetPage = (): IPageList => ({
  offset: 0,
  limit: COUNT_ROWS_LIST,
});

export const createApiParams = <F extends IObject>(
  sort: ISortList,
  pageData: IPageList,
  filters: F,
): IApiParamsObject => {
  const apiParams: IApiParamsObject = {
    api: {},
    url: '',
  };

  if (sort.column !== null) {
    apiParams.api['sortBy'] = sort.column;
    apiParams.api['sort'] = sort.dir;
  }

  apiParams.api['offset'] = pageData.offset;
  apiParams.api['limit'] = pageData.limit;

  Object.keys(filters).map((key) => {
    if (filters[key]) {
      if (key === 'searchQuery') {
        const val = filters[key] as string;
        if (val.indexOf('tag') !== -1) {
          apiParams.api['tags'] = [val.replace('tag:', '').trim()];
          return;
        }
      }
      apiParams.api[key] = filters[key];
    }
  });

  // apiParams.api['tags'] = ['tag1', 'tag2'];

  apiParams.url = queryString.stringify(Object.assign({}, apiParams.api, { offset: undefined, limit: undefined }), {
    arrayFormat: 'bracket',
  });

  return apiParams;
};
