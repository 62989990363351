import React from 'react';
import { Select as AntSelect } from 'antd';

import { Icon } from '../Icon/Icon';

import s from './Select.module.scss';

interface ISelect {
  className?: string;
  type?: 'default' | 'contributionsIntreval';
  value: string;
  options?: Array<{ value: string; label: string }>;
  onChange: (val: string) => void;
  allowClear?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

export const Select: React.FC<ISelect> = ({
  className,
  disabled = false,
  // type = 'default',
  value,
  options = [],
  onChange,
  allowClear = false,
  placeholder = '',
}) => (
  <React.Fragment>
    <AntSelect
      allowClear={allowClear}
      disabled={disabled}
      className={`${s.select} ${className}`}
      placeholder={placeholder}
      value={value || undefined}
      onChange={onChange}
      suffixIcon={<Icon name="select-arrow" />}
    >
      {options &&
        options.map((option) => (
          <AntSelect.Option key={option.value} value={option.value}>
            {option.label}
          </AntSelect.Option>
        ))}
    </AntSelect>
  </React.Fragment>
);
