import React from 'react';

import { Button } from 'antd';

import s from './LoadMore.module.scss';

export const LoadMore: React.FC<{ loading: boolean; nextPage?: () => void }> = ({ loading, nextPage }) => (
  <Button
    className={s.antFormLoadMore}
    onClick={!loading ? nextPage : (e) => e.preventDefault()}
    disabled={loading}
    loading={loading}
  >
    Load more
  </Button>
);
