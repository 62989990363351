import React from 'react';

import s from './Icon.module.scss';

import { ReactComponent as StarIcon } from '../../../assets/images/icons/star.svg';
import { ReactComponent as NotUserIcon } from '../../../assets/images/icons/not-user.svg';
import { ReactComponent as SpeedIcon } from '../../../assets/images/icons/speed.svg';
import { ReactComponent as SelectArrowIcon } from '../../../assets/images/icons/select-arrow.svg';
import { ReactComponent as SearchIcon } from '../../../assets/images/icons/search.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/close.svg';
import { ReactComponent as DownArrowIcon } from '../../../assets/images/icons/down-arrow.svg';

interface IconProps {
  name: string;
  width?: string;
}

export const Icon: React.FC<IconProps> = ({ name, width }) => (
  <span className={s.iconWrapper}>
    {name === 'star' && <StarIcon />}
    {name === 'not-user' && <NotUserIcon width={width} />}
    {name === 'speed' && <SpeedIcon />}
    {name === 'select-arrow' && <SelectArrowIcon />}
    {name === 'search' && <SearchIcon />}
    {name === 'close' && <CloseIcon />}
    {name === 'down-arrow' && <DownArrowIcon />}
  </span>
);
