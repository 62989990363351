import React from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import { goToUrl } from '../../utils/create-url';

import s from './ListRow.module.scss';

interface IListRow {
  children?: React.ReactNode;
  link?: '_self' | '_blank';
  linkTo?: string;
  cursor?: boolean;
}

export const ListRow: React.FC<IListRow> = ({ linkTo, link, cursor = false, children }) => {
  const history = useHistory();
  return (
    <div
      onClick={() => {
        if (linkTo && link === '_self') {
          return history.push(linkTo);
        }

        if (linkTo && link === '_blank') {
          return goToUrl(linkTo);
        }
      }}
      className={classnames(s.listRow, { [s.cursor]: cursor })}
    >
      {children}
    </div>
  );
};
