import React from 'react';

import { View } from '../../../../common/components/View/View';

import s from './AboutView.module.scss';

export const AboutView: React.FC = () => (
  <div className={s.aboutWrapper}>
    <View type="noHead">
      <h1>Recognition of Ignite Community Non-Code Contributors</h1>
      <p>
        <a href="https://ignite.apache.org">Apache Ignite</a> is a distributed database for high-performance computing
        with in-memory speed. Ignite top coders are identified and recognized on
        <a href="https://github.com/apache/ignite"> the Ignite GitHub page</a>. Now, we can identify and recognize the
        Ignite experts who create blog posts, tutorials, and videos and present at events. Our recognition tool embodies
        the Apache Software Foundation (ASF) principle that all types of contributions, code and non-code, are equally
        important.
      </p>
      <br />
      <br />
      <h3>How the program`s website works</h3>
      <p>
        The <a href="/">Project Awareness Top Contributors page</a> identifies Ignite`s top non-code contributors. For
        each contribution (tutorial, demo, conference presentation, and so on), a contributor earns one point. The
        contributors are ranked by number of points and, thus, by number of non-code contributions. Use the filter on
        the right to identify the most active contributors for a particular time period. Select a photo or name and
        click to identify the contributions of your selected Igniter.
        <br />
        <br />
        The &nbsp;
        <a href="https://recognition.gridgain.com/main?date=alltime&typeGroup=answers">
          User Forums Top Contributors page
        </a>
        &nbsp; identifies the Ignite experts who share their knowledge with developers by answering Apache Ignite
        questions on StackOverflow. Each answer earns one point.
        <br />
        <br />
        Select Contributors (top right) to identify the most active contributors for a particular time period. Click a
        photo or name to review the contributions of the Ignite expert that you selected.
        <br />
        <br />
        Select Contributions (top right) to access links to the content created by Ignite contributors. Use the tabs on
        the top to specify a type of contribution (Project Awareness, User Forums) and time period.
      </p>
      <br />
      <br />

      <h3>How to add your contributions to the list</h3>
      <p>
        Currently, the site is in test mode. So, if your contribution isn’t included, please send to the &nbsp;
        <a href="mailto:dev@ignite.apache.org">devlist</a> a link to your contribution and provide a few words about you
        and your contribution. Contributions must be original and focused on Apache Ignite—features and usage. Also,
        contributions must be available to the public (via blog, GitHub, YouTube, or other media) or have been presented
        at a public event.
        <br />
        <br />
        Also, If you often answer Ignite questions on StackOverFlow and you are not in the list, please contact the
        community via <a href="mailto:dev@ignite.apache.org">the devlist</a>.
      </p>
    </View>
  </div>
);
