export const API_LIST_USERS = '/users/';
export const API_ADD_USER = '/users/';
export const API_EDIT_USER = '/users/:id';
export const API_GET_USER = '/users/:id';
export const API_REMOVE_USER = '/users/:id';
export const API_GET_USER_ME = '/users/me';
export const API_LIST_USER_TYPES = '/stats/groupby/type';
export const API_GET_USER_CHART_DATA = '/stats/groupby/date';

export const API_LIST_CONTRIBUTIONS = '/contributions/';
export const API_ADD_CONTRIBUTION = '/users/:id/contributions/';
export const API_EDIT_CONTRIBUTION = '/contributions/:id';
export const API_GET_CONTRIBUTION = '/contributions/:id';
export const API_REMOVE_CONTRIBUTIONS = '/contributions/:id';
