import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

import { Link } from 'react-router-dom';

import s from './Menu.module.scss';

import { MENU } from '../../constants/menu';

import { Icon } from '../Icon/Icon';
import { Hamburger } from '../Hamburger/Hamburger';

export const Menu: React.FC = () => {
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <nav className={s.navWrapper}>
      <ul className={classnames(s.nav, { [s.openMenu]: openMenu })}>
        {MENU.map((item, index) => (
          <li className={classnames(s.navItem, { [s.navItemActive]: location.pathname === item.url })} key={index}>
            <Link className={classnames(s.navItemLink, { [s.navItemLinkIcon]: item.icon })} to={item.url}>
              {item.icon && <Icon name={item.icon} />}
              <span>{item.title}</span>
            </Link>
          </li>
        ))}
      </ul>
      <Hamburger openMenu={openMenu} setOpenMenu={() => setOpenMenu(!openMenu)} />
    </nav>
  );
};
