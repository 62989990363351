import React, { useEffect } from 'react';

import { useTable } from '../../../../common/hooks/useTable';

import s from './ProfileContributions.module.scss';

import { ProfileContributionsMenu } from '../ProfileContributionsMenu/ProfileContributionsMenu';
import { ProfileContributionsTable } from '../ProfileContributionsTable/ProfileContributionsTable';

import { apiGetDevContributions } from '../../../contributions/api/get-dev-contributions';
import { IContribution, IContributionsFilter } from '../../../contributions/typings/contribution.type';

import { Select } from '../../../../common/components/Select/Select';
import { optionsSelect } from '../../../../common/utils/date-interval';

export const ProfileContributions: React.FC<{ id: string }> = ({ id }) => {
  const { listData, filterData, loading, setFilter, loadingLoadMore, nextPage, serachTag } = useTable<
    IContribution,
    IContributionsFilter
  >(
    apiGetDevContributions,
    {
      type: 'Project Awareness',
      searchQuery: '',
      dateFrom: '',
      dateTo: '',
      date: 'alltime',
      typeGroup: 'content',
    },
    undefined,
    { userId: id },
    true,
    'dateTo',
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={s.profileContributionsWrapper}>
      <div className={s.leftFilters}>
        <Select
          type="contributionsIntreval"
          onChange={(val) => {
            setFilter(null, undefined, { date: val });
          }}
          value={filterData.date}
          options={optionsSelect}
        />
        <ProfileContributionsMenu
          id={id}
          activeType={filterData.type}
          date={filterData.date}
          onChangeType={(type) => {
            setFilter(null, 'type', type);
          }}
        />
      </div>
      <ProfileContributionsTable
        nextPage={nextPage}
        loadingLoadMore={loadingLoadMore}
        loading={loading}
        listData={listData}
        serachTag={serachTag}
      />
    </div>
  );
};
