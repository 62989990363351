import React from 'react';

import { Menu } from 'antd';

import { GRIDGAIN_PRODUCT, IGNITE_PRODUCT } from '../../constants/product';

import { getProductName } from '../../utils/product';
import { goToUrlNewProduct } from '../../utils/create-url';

export const MenuChooseProduct: React.FC = () => (
  <Menu>
    <Menu.Item
      key="0"
      onClick={() => {
        goToUrlNewProduct(GRIDGAIN_PRODUCT);
      }}
    >
      {getProductName(GRIDGAIN_PRODUCT)}
    </Menu.Item>
    <Menu.Item
      key="1"
      onClick={() => {
        goToUrlNewProduct(IGNITE_PRODUCT);
      }}
    >
      {getProductName(IGNITE_PRODUCT)}
    </Menu.Item>
  </Menu>
);
