import React from 'react';

import { TableSearch } from '../../../../common/components/TableSearch/TableSearch';
import { Select } from '../../../../common/components/Select/Select';

import { IDevsFilter } from '../../typings/devs.type';

import s from './LeaderboardFilter.module.scss';

import { optionsSelect } from '../../../../common/utils/date-interval';
import { Switcher, SwitcherValues } from '../../../../common/components/Switcher/Switcher';

import { ReactComponent as AwarenessIcon } from '../../../../assets/images/icons/options.svg';
import { ReactComponent as CommunityIcon } from '../../../../assets/images/icons/community.svg';
import { getProduct } from '../../../../common/utils/product';

interface ILeaderboardFilter {
  value: IDevsFilter;
  setFilter: (valOb: { [key: string]: string }) => void;
}

const switcherValues: SwitcherValues[] = [
  { icon: <AwarenessIcon />, text: 'Project Awareness', key: 'content' },
  { icon: <CommunityIcon />, text: 'User Forums', key: 'answers' },
];

export const LeaderboardFilter: React.FC<ILeaderboardFilter> = ({ value, setFilter }) => {
  const isShowSwitcher = getProduct() !== 'gridgain';

  return (
    <div className={s.wrapperFilter}>
      {isShowSwitcher ? (
        <Switcher
          values={switcherValues}
          defaultValue={value.typeGroup}
          onChange={(val) => setFilter({ typeGroup: val })}
        />
      ) : (
        <span />
      )}
      <Select
        type="contributionsIntreval"
        onChange={(val) => {
          setFilter({ date: val });
        }}
        className={s.dateSelect}
        value={value.date}
        options={optionsSelect}
      />
      <TableSearch
        value={value.searchQuery}
        placeholder={'Search by a contributor name'}
        onChange={(valNew) => {
          setFilter({ searchQuery: valNew });
        }}
      />
    </div>
  );
};
