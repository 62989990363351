import React from 'react';

import { GuestRoutes } from './routes/guest.routes';
import { AuthRoutes } from './routes/auth.routes';

export const RouterProvider: React.FC = () => {
  const isAuth = false;

  return <React.Fragment>{isAuth ? <AuthRoutes /> : <GuestRoutes />}</React.Fragment>;
};
