import moment from 'moment';

export const optionsSelect = [
  { label: 'All time score', value: 'alltime' },
  { label: 'This quarter', value: 'this-quarter' },
  { label: 'Previous quarter', value: 'prev-quarter' },
  { label: `This year (${moment().format('YYYY')})`, value: 'this-year' },
  { label: `Previous year (${moment().subtract(1, 'year').format('YYYY')})`, value: 'prev-year' },
];

interface ICreateIntervalDateReturn {
  [key: string]: string;
}

export const createIntervalDate = (value: string | null, type?: string): ICreateIntervalDateReturn => {
  let titleStart = 'contributionsFrom';
  let titleEnd = 'contributionsTo';

  if (type === 'dateTo') {
    titleStart = 'dateFrom';
    titleEnd = 'dateTo';
  }

  if (!value) {
    return {};
  }

  if (value === 'alltime') {
    return {};
  }

  if (value === 'this-quarter') {
    const start = moment().quarter(moment().quarter()).startOf('quarter').format('YYYY-MM-DD');
    const stop = moment().quarter(moment().quarter()).endOf('quarter').format('YYYY-MM-DD');
    return { [titleStart]: start, [titleEnd]: stop };
  }

  if (value === 'prev-quarter') {
    const start = moment().subtract(1, 'Q').startOf('quarter').format('YYYY-MM-DD');
    const stop = moment().subtract(1, 'Q').endOf('quarter').format('YYYY-MM-DD');
    return { [titleStart]: start, [titleEnd]: stop };
  }

  if (value === 'this-year') {
    const start = moment().format('YYYY-01-01');
    const stop = moment().format('YYYY-12-31');

    return { [titleStart]: start, [titleEnd]: stop };
  }

  if (value === 'prev-year') {
    const start = moment().subtract(1, 'y').format('YYYY-01-01');
    const stop = moment().subtract(1, 'y').format('YYYY-12-31');

    return { [titleStart]: start, [titleEnd]: stop };
  }

  return { [titleStart]: '', [titleEnd]: '' };
};
