import { IObject } from '../typings/object.type';

import { checkProductGridgain } from './product';

export const getItem = (name: string): string | null | IObject => {
  const data = localStorage.getItem(name);
  if (data) {
    try {
      return JSON.parse(data);
    } catch (err) {
      if (typeof data === 'string') {
        return data;
      }
    }
  }

  return null;
};

export const setItem = <T>(name: string, data: T | string): void => {
  checkProductGridgain(name, data as string);
  localStorage.setItem(name, typeof data === 'string' ? data : JSON.stringify(data));
};

export const removeItem = (name: string): void => {
  localStorage.removeItem(name);
};
