import React from 'react';

import s from './TableCellTitle.module.scss';

export const TableCellTitle: React.FC<{ date?: string; type?: string; url: string; children?: React.ReactNode }> = ({
  children,
  url,
  type,
  date,
}) => (
  <div className={s.tableCellTitle}>
    {date && <span className={s.dateTitle}>{date}</span>}
    <a href={url} target="_blank" rel="noreferrer">
      {children}
    </a>
    {type && <span className={s.typeTitle}>{type}</span>}
  </div>
);
