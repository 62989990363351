import { httpGET } from '../../../common/utils/http';
import { IApiRequest } from '../../../common/typings/api.type';

import { API_GET_USER } from '../../../common/constants/api';

import { IDevs } from '../../leaderboard/typings/devs.type';

import { getProduct } from '../../../common/utils/product';

export const apiGetDev = async ({ options, params }: IApiRequest): Promise<IDevs | null> =>
  await httpGET<IDevs>({ url: API_GET_USER, options, params: Object.assign({ ...params }, { product: getProduct() }) });
