import React from 'react';
import queryString from 'query-string';

import { View } from '../../../../common/components/View/View';
import { List } from '../../../../common/components/List/List';
import { ListRow } from '../../../../common/components/ListRow/ListRow';
import { ListTags } from '../../../../common/components/ListTags/ListTags';
import { ListCell } from '../../../../common/components/ListCell/ListCell';
import { UserInfo } from '../../../../common/components/UserInfo/UserInfo';
import { UserScore } from '../../../../common/components/UserScore/UserScore';
import { useTable } from '../../../../common/hooks/useTable';

import { IDevs, IDevsFilter } from '../../typings/devs.type';

import { LeaderboardFilter } from '../LeaderboardFilter/LeaderboardFilter';

import { apiDevsList } from '../../api/get-devs-list';

import { URL_PROFILE } from '../../../../common/constants/url';
import { getProduct } from '../../../../common/utils/product';
import {
  communitySupport,
  projectAwareness,
} from '../../../profile/components/ProfileContributionsMenu/ProfileContributionsMenu';

export const LeaderboardTable: React.FC = () => {
  const { listData, filterData, loading, loadingLoadMore, setFilter, nextPage } = useTable<IDevs, IDevsFilter>(
    apiDevsList,
    {
      searchQuery: '',
      dateFrom: '',
      dateTo: '',
      date: 'alltime',
      typeGroup: 'content',
    },
    undefined,
    { sortBy: 'score', sort: 'DESC' },
    true,
  );

  const title = `${filterData.typeGroup === 'content' ? projectAwareness : communitySupport} Top Contributors`;
  const text = `${
    filterData.typeGroup === 'content'
      ? 'The Project Awareness list identifies the Ignite experts who have created demos, blog posts, and tutorials about Ignite and have presented on Ignite topics at various online and offline events. By sharing their knowledge with developers from all over the world, these experts are increasing awareness about Apache Ignite.'
      : `The User Forums' page identifies the Ignite experts who share their knowledge with developers by answering Apache Ignite questions on StackOverFlow. These experts contribute their time and experience to help developers implement Ignite and expand the use of Ignite throughout the world.`
  }`;

  const description =
    getProduct() === 'gridgain'
      ? `This leaderboard identifies GridGain employees who have worked to increase awareness about Apache Ignite and GridGain products. The leaderboard celebrates all who have shared their knowledge with the developer community—all those Ignite experts who have written articles, created videos and demos, hosted trainings, and presented at online and offline conferences and meetups.`
      : text;

  return (
    <View
      loading={loading}
      loadingLoadMore={loadingLoadMore}
      loadingLoadShow={!(listData && listData.data.length === listData.count)}
      nextPage={nextPage}
      type="head"
      componentSubHeader={
        <LeaderboardFilter
          setFilter={(valOb) => {
            setFilter(null, undefined, valOb);
          }}
          value={filterData}
        />
      }
      title={title}
      description={description}
    >
      <List>
        {listData &&
          listData.data.map((item, index) => (
            <ListRow
              key={index}
              cursor
              link="_self"
              linkTo={`${URL_PROFILE.replace(':id', item.id)}${
                filterData.date ? `?${queryString.stringify({ date: filterData.date })}` : ''
              }`}
            >
              <div style={{ width: 250 }}>
                <ListCell>
                  <UserInfo
                    // text={'Click to view user Rank'}
                    urlParams={{ date: filterData.date }}
                    src={item.avatarUrl}
                    leftRound
                    name={item.name}
                    id={item.id}
                    countDisabled={filterData.searchQuery !== ''}
                    count={index + 1}
                    totalCount={listData?.withCountributionsCount || 0}
                  />
                </ListCell>
              </div>

              <ListCell mobileDisabled>
                <div style={{ marginLeft: 5, width: '100%', height: 40 }}>
                  <ListTags tags={item.contributions.tags} />
                </div>
              </ListCell>

              <div style={{ width: 120 }}>
                <ListCell mobileSmall style={{ textAlign: 'center' }}>
                  <UserScore score={item.contributions.score} />
                </ListCell>
              </div>
            </ListRow>
          ))}
      </List>
    </View>
  );
};
